import { useLocation } from "react-router-dom";
import React from "react";
import ImageLoader from "../../../components/imageloader/imageloader";
import "./posts.scss";

const Posts: React.FC = () => {
    const location = useLocation();
    const { postData } = location.state;

    return (
        <>
            {postData ? (
                <div className='post-hero'>
                    <div className='post-wrapper'>
                        <div className='top-half'>
                            <div className='top-title'>
                                <img src={postData.uri} alt="title" />
                                <p>
                                    {postData.date
                                        ? postData.date.toString()
                                        : ""}
                                </p>
                            </div>
                            <div className='info-box'>
                                <div className='title-wrapper'>
                                    <p>{postData.title}</p>
                                </div>
                                <div className='tags'>
                                    {postData.tags  
                                        ? postData.tags.map(
                                              (tag: string, index: number) => (
                                                  <p className='web'>
                                                      {tag.toLocaleLowerCase()}
                                                  </p>
                                              )
                                          )
                                        : null}
                                </div>
                                <div className='links-wrapper'>
                                    <p>Links: </p>
                                    {postData.links
                                        ? postData.links.map(
                                              (link: string, index: number) => (
                                                  <a key={index} href={link}>
                                                      {link}
                                                  </a>
                                              )
                                          )
                                        : null}
                                </div>
                            </div>
                        </div>
                        <p>{postData.body ? postData.body : ""}</p>
                        <div>
                            {postData.images ? <p>Gallery: </p> : null}
                            <div className='gallery'>
                                {postData.images
                                    ? postData.images.map(
                                          (image: string, index: number) => (
                                              <ImageLoader
                                                  alt={image}
                                                  src={image}
                                                  key={index}
                                              />
                                          )
                                      )
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>Loading...</>
            )}
        </>
    );
};

export default Posts;
