import { useState } from "react";
import './ssl.scss';
import axios from "axios";
import { toast } from "react-toastify";

const Ssl = () => {
    const [domainName, setDomainName] = useState<string>("");

    const triggerSSLRequest = async () => {
        try {
            const body = {
                domainName: domainName,              
            };
            toast.warning("Adding SSL...");
            const response = await axios.post('https://api.sirendigital.uk/ssl/certbot', body);
            toast.success("SSL created successfully!");
        } catch (error) {
            console.error("Error executing SSL command:", error);
            toast.error("Error adding SSL!");
        }
    };

    return (
        <div className='hero'>
            WORK IN PROGRESS
            <div className='wrapper'>
                <h1>Auto SSL Certificate</h1>
                <p className='bodyCopy'>
                    Enter the domain you want to generate an SSL certificate
                    for.
                </p>
                <div className='inp-wrapper'>
                    <div className="inp-contain">
                        <p className='btnCap'>Domain: </p>
                        <div className='btn-container'>
                            <input
                                className='inpField'
                                type='text'
                                value={domainName}
                                onChange={(e) =>
                                    setDomainName(e.target.value.toString())
                                }
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") triggerSSLRequest();
                                }}
                            />
                        </div>
                    </div>
                        <button className='addBtn' onClick={triggerSSLRequest}>
                            Generate
                        </button>
                </div>
            </div>
        </div>
    );
};
export default Ssl;
