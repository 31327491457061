import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore/lite';
import 'firebase/firestore';

let firebaseConfig;

// if (process.env.STAGE === 'dev') {
    // firebaseConfig = require('../../firebaseConfig');
// } else {
    firebaseConfig = {
        authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
        projectId: "siren-design-internal-tools",
        storageBucket: "siren-design-internal-tools.appspot.com",
        messagingSenderId: "498969863809",
        appId: process.env.REACT_APP_FIREBASE_APPID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
    };
// }

const app = initializeApp(firebaseConfig);

// eslint-disable-next-line
export default getFirestore(app);