import { Dropbox } from "dropbox";
import { Md5 } from "ts-md5";

/**
 * Service class for interacting with Dropbox API.
 */
class DropboxService {
    private dbx: Dropbox;

    /**
     * Constructs a new instance of the DropboxService class.
     * @param accessToken The access token for authenticating with Dropbox API.
     */
    public constructor(accessToken: string) {
        console.log("Dbx: Initializing");
        this.dbx = new Dropbox({ accessToken });
        this.dbx
            .usersGetCurrentAccount()
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.error(error);
            });
    }

    /**
     * Uploads an image to Dropbox.
     * @param path The path where the image should be uploaded.
     * @param file The image file to upload.
     * @returns The public URL of the uploaded image.
     */
    async uploadImage(path: string, file: File) {
        try {
            const contents = await this.readFileContents(file);
            const fileName: string = path + file.name;
            const hash = Md5.hashStr(fileName + file.size);
            const filePath = "/" + hash + file.name;
            const response = await this.dbx.filesUpload({
                path: filePath,
                contents: contents,
            });
            console.log("Image uploaded successfully! Response:", response);

            try {
                const sharingResponse =
                    await this.dbx.sharingCreateSharedLinkWithSettings({
                        path: filePath,
                        settings: {
                            requested_visibility: { ".tag": "public" },
                        },
                    });
                const url = sharingResponse.result.url.replace("dl=0", "raw=1");
                return url;
            } catch (error) {
                console.error("Error creating shared link:", error);
                return "";
            }
        } catch (error) {
            console.error("Error uploading image:", error);
            return "";
        }
    }

    /**
     * Reads the contents of a file as a Uint8Array.
     * @param file The file to read.
     * @returns A promise that resolves with the file contents as a Uint8Array.
     */
    private readFileContents(file: File): Promise<Uint8Array> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                if (
                    event.target &&
                    event.target.result instanceof ArrayBuffer
                ) {
                    resolve(new Uint8Array(event.target.result));
                } else {
                    reject(new Error("Failed to read file contents."));
                }
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsArrayBuffer(file);
        });
    }
}

export default DropboxService;
