import React, { useState, useEffect } from "react";
import './imageloader.scss';
import { Skeleton } from "@mui/material";

interface ImageLoaderProps {
    src: string;
    alt: string;
}

const ImageLoader: React.FC<ImageLoaderProps> = ({ src, alt }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const imageLoader = new Image();
        imageLoader.src = src;
        imageLoader.onload = () => {
            setLoaded(true);
        };

        const timeout = setTimeout(() => {
            setLoaded(true);
        }, 5000);

        return () => {
            clearTimeout(timeout);
        };
    }, [src]);

    return (
        <div className='image-loader'>
            {loaded ? (
                <img src={src} alt={alt} className="image" />
            ) : (
                <div className='loader' style={{width: "100%"}}><Skeleton variant="rectangular" animation="wave" width={1000} height={400}></Skeleton></div>
            )}
        </div>
    );
};

export default ImageLoader;