import { SITE_TITLE } from "../../utils/consts";
import DesktopNav from "../nav/desktop/desktop";
import "./header.scss";

const Header: React.FC = () => {
    return (
        <header>
            <div className='hero-wrapper'>
                <div className='hdr-wrapper'>
                    <h2>
                        <a href='/feed' style={{position: 'relative', zIndex: '20'}}>{SITE_TITLE}</a>
                    </h2>
                    <DesktopNav />
                </div>
            </div>
        </header>
    );
};

export default Header;
