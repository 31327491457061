import firebase from "./firebase";
import Post from "../../types/post";
import {
    collection,
    getDocs,
    addDoc,
    query,
    where,
    DocumentData,
    Query,
    limit,
    startAfter,
    orderBy,
    endBefore,
    limitToLast,
} from "firebase/firestore/lite";

class DataService {
    db = collection(firebase, "posts");
    dbTags = collection(firebase, "tags");

    /**
     * Create a new post.
     * @param post The post object to create.
     * @returns A promise that resolves with the created post.
     */
    async create(post: Post) {
        console.log("Collection: ", this.db);
        return await addDoc(this.db, { post });
    }

    /**
     * Get a post by its ID.
     * @param id The ID of the post to get.
     */
    async get(id: string) {
        console.log("Collection: ", this.db);
    }

    /**
     * Query posts by tags.
     * @param tags An array of tags to query by.
     * @returns A promise that resolves with an array of posts matching the tags.
     */
    async queryAll(tags: string[]) {
        try {
            const q: Query<DocumentData> = query(
                this.db,
                where("post.tags", "array-contains-any", tags)
            );

            console.log("Query: ", q);
            console.log("Query of: ", "tags contains", tags);

            const querySnapshot = await getDocs(q);
            console.log("QuerySnapshot: ", querySnapshot);

            let newData: Post[] = [];
            querySnapshot.docs.forEach((doc) => {
                console.log("Doc: ", doc);
                newData.push({
                    id: doc.id,
                    uri: doc.data().post.uri,
                    title: doc.data().post.title,
                    tags: doc.data().post.tags,
                    body: doc.data().post.body,
                    date: doc.data().post.date,
                });
            });
            console.log(newData);
            return newData as Post[];
        } catch (error) {
            console.error("Error reading data:", error);
        }
    }

    /**
     * Get all posts.
     * @param postPerPage The number of posts to retrieve.
     * @returns A promise that resolves with an array of all posts.
     */
    async getAll(postPerPage: number) {
        try {
            const q: Query<DocumentData> = query(this.db, orderBy('post.date', 'desc'));
            const querySnapshot = await getDocs(q);
            const newData: Post[] = this.formatData(querySnapshot);
            return newData;
        } catch (error) {
            console.error("Error reading data:", error);
        }
        return {} as Post[];
    }

    /**
     * Get a page of posts.
     * @param pageNumber The page number to retrieve.
     * @param lastPost The last post retrieved.
     * @returns Post[] A promise that resolves with an array of posts.
     */

    async getNextPage(lastPost: Post, numberPerPage: number): Promise<Post[]> {
        try {
            const q: Query<DocumentData> = query(
                this.db,
                orderBy('post.date', 'desc'),
                startAfter(lastPost.date),
                limit(numberPerPage)
            );

            const querySnapshot = await getDocs(q);
            const newData: Post[] = this.formatData(querySnapshot);
            console.log("New Data: ", newData);
            return newData;
        } catch (error) {
            console.error("Error reading data:", error);
        }
        return {} as Post[];
    }

    /**
     * Retrieves the previous page of posts based on the provided first post and number of posts per page.
     * @param firstPost - The first post on the current page.
     * @param numberPerPage - The number of posts to retrieve per page.
     * @returns A promise that resolves to an array of Post objects representing the previous page of posts.
     */
    async getPreviousPage(firstPost: Post, numberPerPage: number): Promise<Post[]> {

        try{
            const q: Query<DocumentData> = query(
                this.db,
                orderBy('post.date', 'desc'),
                endBefore(firstPost.date),
                limitToLast(numberPerPage)
            );

            const querySnapshot = await getDocs(q);
            const newData: Post[] = this.formatData(querySnapshot);
            console.log("New Data: ", newData);
            return newData;
        }catch(error){
            console.error("Error reading data:", error);
        }

        return {} as Post[];
    }

    // ? Tags

    /**
     * Post tags (Not implemented).
     * @param tags An array of tags to post.
     */
    async postTags(tags: string[]) {
        console.log("Post Method Not Implemented");
    }

    /**
     * Update tags.
     * @param tags An array of tags to update.
     */
    async updateTags(tags: string[]) {
        try {
            const docRef = (await getDocs(this.dbTags)).docs[0];
            const oldTags: string[] = docRef.data().tags;
            const newTags: string[] = tags.filter(
                (tag) => !oldTags.includes(tag)
            );
            console.log("New Tags: ", newTags);
            addDoc(this.dbTags, { tags: { tags: newTags } });
        } catch (error) {
            console.error("Error reading data:", error);
        }
    }

    /**
     * Get tags.
     * @returns A promise that resolves with an array of tags.
     */
    async getTags() {
        try {
            const docRef = (await getDocs(this.dbTags)).docs[0];
            console.log("Tags: ", docRef.data().tags);
            return docRef.data().tags;
        } catch (error) {
            console.error("Error reading data:", error);
        }
    }

    // Helper Methods

    /**
     * Formats the retrieved data into an array of Post objects.
     * @param data The data retrieved from Firestore.
     * @returns An array of formatted Post objects.
     */
    private formatData(data: any): Post[] {
        const newData: Post[] = data.docs.map((doc: any) => ({
            id: doc.id,
            uri: doc.data().post.uri,
            title: doc.data().post.title,
            tags: doc.data().post.tags,
            body: doc.data().post.body,
            date: doc.data().post.date,
        })) as Post[];

        return newData;
    }
}


// eslint-disable-next-line
export default new DataService();
