import { useState } from "react";
import "./clickup.scss";
import Button from "@mui/material/Button";
import { STAGE } from "../../utils/consts";

const Clickup = () => {
    const [listName, setListName] = useState("");
    const [tasks, setTasks] = useState("");
    const [error, setError] = useState<string>("");
    const [isDisabled, setDisabled] = useState<boolean>(false);

    let CLICKUP_AUTH_KEY: any;

    
        CLICKUP_AUTH_KEY = process.env.CLICKUP_AUTH_KEY;
    

    const handleInput = async () => {
        setError("");
        setDisabled(true);
        if (!listName) {
            setError("Please enter a valid list name");
            setDisabled(false);
            return;
        } else if (!tasks) {
            setError("Please enter at least one task");
            setDisabled(false);
            return;
        }
        try {
            const listId = await createList();
            const parts: string[] = tasks.split("\n");
            parts.forEach((item: string) => {
                addTask(item, listId);
            });
        } catch (error) {
            setError("An Error Occurred When handling Input");
            setDisabled(true);
            console.error("Error in handleInput:", error);
        }
    };

    const addTask = async (content: string, listId: string) => {
        try {
            const resp = await fetch(
                `https://api.clickup.com/api/v2/list/${listId}/task?`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${CLICKUP_AUTH_KEY}`,
                    },
                    body: JSON.stringify({
                        name: content,
                    }),
                }
            );
            console.log("Task Added:", resp);
        } catch (error) {
            console.error("Error in addTask:", error);
        }
    };

    const createList = async () => {
        try {
            const SPACE_ID = "90120168941";
            const resp = await fetch(
                `https://api.clickup.com/api/v2/space/${SPACE_ID}/list`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `${CLICKUP_AUTH_KEY}`,
                    },
                    body: JSON.stringify({
                        name: `${listName}`,
                    }),
                }
            );
            const data = await resp.json();
            console.log(data);
            return data.id;
        } catch (error) {
            setError("List Name Invalid");
            console.error("Error in createList:", error);
        }
    };

    const renderPreview = () => {
        const taskLines = tasks.split("\n");
        const tasksList = taskLines.map((task, index) => (
            <li key={index}>{task}</li>
        ));
        return <ul>{tasksList}</ul>;
    };

    return (
        <div className='hero'>
            <div className='hero-container wrapper'>
                <h1>ClickUp List Generator</h1>
                <div className='inputContainer'>
                    <label htmlFor='input3'>List Name:</label>
                    <input
                        type='text'
                        id='input3'
                        placeholder='List Name'
                        value={listName}
                        onChange={(e) => setListName(e.target.value)}
                    />
                </div>
                <div className='inputContainer'>
                    <label htmlFor='input4'>Tasks:</label>
                    <textarea
                        id='input4'
                        placeholder='Enter Tasks, seperated by a new line'
                        value={tasks}
                        onChange={(e) => setTasks(e.target.value)}
                    />
                </div>
                <div>
                    <label>Preview</label>
                    <p>{renderPreview()}</p>
                </div>
                <Button
                    className={`classic ${isDisabled ? "disabled" : ""}`}
                    disabled={isDisabled}
                    onClick={handleInput}
                >
                    Generate List and Tasks
                </Button>
                <p style={{ color: "red" }}>{error}</p>
            </div>
        </div>
    );
};

export default Clickup;
