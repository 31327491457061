import React from "react";
import "./bubbles.scss";

interface LeftBubbleProps {
    message: string;
}

const leftBubble: React.FC<LeftBubbleProps> = ({ message }) => {
    return (
        <div className='left-wrap'>
            <div className='left-bubble'>
                <p>{message}</p>
            </div>
        </div>
    );
};

export default leftBubble;
