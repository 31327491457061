import { useState } from "react";
import "./login.scss";

interface LoginProps {
    onLogin: () => void;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<string>();
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log("username:", username);
        console.log("password:", password);
    };

    const handleLoginClick = () => {
        if (
            username === 'blaine' &&
            password === 'blaine'
        ) {
            onLogin();
        } else {
            setError("Incorrect username or password");
        }
    };

    return (
        <div className='hero'>
            <div className='hero-padder'>
                <form className='loginForm' onSubmit={handleSubmit}>
                    <label>
                        Username:
                        <input
                            type='text'
                            value={username}
                            onChange={(event) =>
                                setUsername(event.target.value)
                            }
                        />
                    </label>
                    <label>
                        Password:
                        <input
                            type='password'
                            value={password}
                            onChange={(event) =>
                                setPassword(event.target.value)
                            }
                        />
                    </label>
                    <label className='error'>{error}</label>
                    <button
                        type='submit'
                        onClick={handleLoginClick}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") handleLoginClick();
                        }}
                    >
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
