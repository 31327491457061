import { Link } from "react-router-dom";
import "./desktop.scss";
import { useState } from "react";

const DesktopNav = () => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const [isDev, setDev] = useState<boolean>(false);
    const [isAi, setAi] = useState<boolean>(false);

    const handleOpen = () => {
        console.log("Open");
        setOpen(!isOpen);
        setDev(false);
        setAi(false);
    };

    return (
        <>
            <div className='button'>
                {isOpen ? (
                    <div className='internal-links'>
                        <Link
                            onClick={handleOpen}
                            className='menu-item'
                            to='/feed'
                        >
                            Feed
                        </Link>
                        <div className='sub'>
                            <div
                                className='menu-item sub'
                                onClick={() => setAi(!isAi)}
                            >
                                Ai Tools
                            </div>
                            {isAi ? (
                                <>
                                    <Link
                                        onClick={handleOpen}
                                        className='menu-item mini'
                                        to='/gpt-ui'
                                    >
                                        Gpt
                                    </Link>
                                    <Link
                                        onClick={handleOpen}
                                        className='menu-item mini'
                                        to='/toolkit'
                                    >
                                        Toolkit
                                    </Link>
                                </>
                            ) : null}
                        </div>
                        <Link
                            onClick={handleOpen}
                            className='menu-item'
                            to='/clickup'
                        >
                            ClickUp
                        </Link>
                        <div className='sub'>
                            <div
                                className='menu-item sub'
                                onClick={() => setDev(!isDev)}
                            >
                                Dev Tools
                            </div>
                            {isDev ? (
                                <>
                                    <Link
                                        onClick={handleOpen}
                                        className='menu-item mini'
                                        to='/server-setup'
                                    >
                                        Server Setup
                                    </Link>
                                    <Link
                                        onClick={handleOpen}
                                        className='menu-item mini'
                                        to='/mailer'
                                    >
                                        Mailer
                                    </Link>                                 
                                    <Link
                                        onClick={handleOpen}
                                        className='menu-item mini'
                                        to='/ssl'
                                    >
                                        SSL
                                    </Link>                                 
                                </>
                            ) : null}
                        </div>
                    </div>
                ) : null}
                <div onClick={handleOpen} className='x'>
                    menu
                </div>
            </div>
        </>
    );
};

export default DesktopNav;
