import Typewriter from "../typewriter/typewritter";
import "./bubbles.scss";

interface RightBubbleProps {
    message: string;
}

const RightBubble: React.FC<RightBubbleProps> = ({ message }) => {
    return (
        <div className='right-wrap'>
            <div className='right-bubble'>
                <p>{message ? <Typewriter text={message} delay={35} /> : ' '}</p>
            </div>
        </div>
    );
};

export default RightBubble;
