import axios from "axios";
import { BASE_GPT, BASE_PROMPT } from "../../utils/consts";
import { ToolkitOutput } from "../../types/gpt";

let API_KEY: string | undefined;
// if (process.env.STAGE === "dev") {
//     require('../../utils/API_KEY');
// } else {
    API_KEY = process.env.GPT_KEY;
// }



    class GptService {

        
        async gptPost(input: string): Promise<string> {
            const apiUrl = "https://api.openai.com/v1/chat/completions";
            const data = {
                model: "gpt-4",
                messages: [{ role: "user", content: input }],
            };

            const headers = {
                Authorization: `Bearer ${API_KEY}`,
            };

            try {
                const response = await axios.post(apiUrl, data, { headers });
                let result = "";
                if (response.data.choices && response.data.choices.length > 0) {
                    result = response.data.choices[0]?.message?.content || "";
                }
                return result;
            } catch (error) {
                console.log("Error:", error);
                return "";
            }
        }
    

    callGpt(input: any): Promise<ToolkitOutput> {
        console.log("Invoked ChatGPT");
        const apiUrl = "https://api.openai.com/v1/chat/completions";
        const data = {
            max_tokens: 500,
            model: "gpt-4",
            messages: [
                {
                    role: "user",
                    content: `${BASE_GPT} + ${input.values.map(
                        (
                            value: { question: any; answer: any },
                            index: number
                        ) => `${index + 1}. ${value.question} ${value.answer}`
                    )} + ${BASE_PROMPT}`,
                },
            ],
        };

        const headers = {
            Authorization: `Bearer ${API_KEY}`,
        };

        return axios
            .post(apiUrl, data, { headers })
            .then((response) => {
                console.log(
                    "First GPT Response: " +
                        response.data.choices[0].message.content
                );
                return JSON.parse(response.data.choices[0].message.content);
            })
            .catch((error) => {
                console.log({ error });
                return {} as ToolkitOutput;
            });
    }

    async generateImage(res: string) {
        try {
            const apiUrl = "https://api.openai.com/v1/images";
            const data = {
                model: "dall-e-3",
                prompt: `a logo (No image of a building) with the brands name or initials somehow incorporated; based on this: ${res}`,
                n: 1,
                size: "1024x1024",
                quality: "hd",
            };

            const headers = {
                Authorization: `Bearer ${API_KEY}`,
            };

            const response = await axios.post(apiUrl, data, { headers });
            console.log(response.data[0].url);

            return response;
        } catch (err) {
            console.log("Image generation failed");
        }
    }
}

// eslint-disable-next-line
export default new GptService();
