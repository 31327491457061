import { GPTScript } from "../../types/gpt.d";
import './toolkit.scss';

const DisplayResponse: React.FC<GPTScript> = ({
    toolkitOutput,
    toolkitInput,
}) => {

    console.log('Toolkit Output: ' + toolkitOutput.values + ' Toolkit Input: ' + toolkitOutput.exampleLogos);
    return (
        <div className='wrapper'>
            <div className='gpt-resp'>
                <p>You Input</p>
                <hr></hr>
                {toolkitInput.values.map((value, index) => (
                    <div key={"question" + index}>
                        <h2>{value.question}</h2>
                        <p>{value.questionPrefix}</p>
                        <p>{value.answer}</p>
                    </div>
                ))}
                <hr></hr>
            </div>
            <div className='gpt-resp'>
                <p>Ai Generated Response</p>
                {toolkitOutput.values.map(({ question, answer }, index) => (
                    <div key={"proposal" + index}>
                        <h2>{question}</h2>
                        <p>{answer}</p>
                    </div>
                ))}
                {toolkitOutput.exampleLogos ? <img src={toolkitOutput.exampleLogos[0]} alt="text" /> : null}
            </div>
        </div>
    );
};

export default DisplayResponse;
