import { useEffect, useState } from "react";
import "./serverSetup.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import axios from "axios";
import ResponsePopup, { ResponseData } from "./response";

const ServerSetup: React.FC = () => {
    const [projectDomain, setProjectDomain] = useState<string>("");
    const [projectName, setProjectName] = useState<string>("");
    const [distFolder, setDistFolder] = useState<string>("");
    const [projectType, setProjectType] = useState<number>(3);
    const [nodeVersion, setNodeVersion] = useState<string>("");
    const [suppressWarnings, setSuppressWarnings] = useState<boolean>(false);
    const [responseBody, setResponse] = useState<string>("");
    const [devSite, setDevSite] = useState<boolean>(true);
    const [sirenDigital, setSirenDigital] = useState<boolean>(false);
    const [siteType, setSiteType] = useState<string>("php");
    
    const handleChange = (e: any) => {
        setProjectDomain(e.target.value);
    };
    const handleChange2 = (e: any) => {
        setProjectName(e.target.value);
    };
    const handleChange3 = (e: any) => {
        setDistFolder(e.target.value);
    };
    const handleChange4 = (e: any) => {
        
        console.log(e.target.value);
        setSiteType(e.target.value == 3 ? "php" : e.target.value == 1 ? "html" : " ");
        setProjectType(e.target.value);
    };
    const handleChangeNodeVersion = (e: any) => {
        setNodeVersion(e.target.value);
    };

    const handleSuppressWarnings = (e: any) => {
        setSuppressWarnings(e.target.checked);
    };

    const executeCommand = async () => {
        try {
            toast.info("Site creation request sent!");
            const body = {
                domainName: projectDomain,
                siteUser: projectName,
                addDevSite: devSite,
                sirenDigital: sirenDigital,
            };
            const response = await axios.post(`https://api.sirendigital.uk/add-site/${siteType}`, body);
            setResponse(response.data);
            toast.success("Site created successfully!");
        } catch (error) {
            console.error("Error executing command:", error);
            toast.error("Error Creating Site!");
        }
    };

    useEffect(() => {
        if (projectType !== 1) {
            setNodeVersion("");
        }
    }, [projectType]);

    const copyToClipboard = (body: string) => {
        navigator.clipboard.writeText(body);
        toast.success("Copied to clipboard!", { autoClose: 500 });
    };

    // eslint-disable-next-line
    const handleDownload = () => {
        if (!projectDomain) {
            toast.error("Please enter a project domain");
            return;
        } else if (!projectName) {
            toast.error("Please enter a project name");
            return;
        } else if (!distFolder) {
            toast.error("Please enter a dist folder");
            return;
        }

        let yamlContent = `name: Automated Deployment
on:
  push:
    branches:
      - master
      - main
      - develop
      - dev
    pull_request:
      branches:
        - master
        - main
        - develop
        - dev
jobs:
  build:
    runs-on: ubuntu-latest
    steps:
      - name: Checkout repository
        uses: actions/checkout@v1`;
        if (nodeVersion) {
            yamlContent += `
      - name: Use Node.js version
        uses: actions/setup-node@v2
        with:
          node-version: '${nodeVersion}'`;
        }

        if (projectType == 1 && suppressWarnings) {
            yamlContent += `
      - name: Install dependencies and build
        run: |
          CI=false
          npm install
          npm run build`;
        } else if (projectType == 1) {
            yamlContent += `
      - name: Install dependencies and build
        run: |
          npm install
          npm run build`;
        }

        yamlContent += `
      - name: Deploy to production
        if: github.ref == 'refs/heads/master' || github.ref == 'refs/heads/main'
        uses: appleboy/scp-action@master
        with:
          HOST: 193.203.191.131
          USERNAME: ${projectName}
          PORT: 22
          source: "${distFolder}/"
          target: ./htdocs/${projectDomain}${
            projectDomain == "4" ? "/wp-content/" : "/"
        }
          PASSWORD: \${{ secrets.SSH_PASSWORD }}
          strip_components: 1

      - name: Deploy to development environment
        if: github.ref == 'refs/heads/develop' || github.ref == 'refs/heads/dev'
        uses: appleboy/scp-action@master
        with:
          HOST: 193.203.191.131
          USERNAME: ${projectName}-dev
          PORT: 22
          source: "${distFolder}/"
          target: ${sirenDigital ? './htdocs/' + projectName + '.sirendigital.uk' : "./htdocs/dev." + projectDomain}${
            projectDomain == "4" ? "/wp-content/" : "/"
        }
          PASSWORD: \${{ secrets.SSH_PASSWORD }}
          strip_components: 1

  send_notification:
    runs-on: ubuntu-latest
    needs: build
    steps:
    - name: Send Teams Notification
      env:
        TEAMS_WEBHOOK_URL: \${{ secrets.TEAM_API }}
      run: |
        if [ "\${{ github.ref }}" = "refs/heads/master" ] || [ "\${{ github.ref }}" = "refs/heads/main" ]; then
          curl -X POST -H 'Content-Type: application/json' -d "{\\"text\\":\\"Deployment completed: www.${projectDomain}<br/>Triggered by: \${GITHUB_ACTOR}<br/>Repository: \${GITHUB_REPOSITORY}<br/>Branch: main<br/> \\"}" "\${TEAMS_WEBHOOK_URL}"
        elif [ "\${{ github.ref }}" = "refs/heads/develop" ] || [ "\${{ github.ref }}" = "refs/heads/dev" ]; then
          curl -X POST -H 'Content-Type: application/json' -d "{\\"text\\":\\"Deployment completed: https://dev.${projectDomain}<br/>Triggered by: \${GITHUB_ACTOR}<br/>Repository: \${GITHUB_REPOSITORY}<br/>Branch: develop<br/> \\"}" "\${TEAMS_WEBHOOK_URL}"
        fi
  
        `;
        const element = document.createElement("a");
        const file = new Blob([yamlContent], { type: "text/plain" });
        element.href = URL.createObjectURL(file);
        element.download = "deploy.yaml";
        document.body.appendChild(element);
        element.click();
    };

    const constructVhost = () => {
        if (!projectDomain) {
            toast.error("Please enter a project domain");
            return;
        }
        const redirect = "dev.siren-demo.com";
        const vhost = `
            # Define a reusable virtual host configuration template

    server {
        # Listen on port 80 for HTTP requests
        listen 80;
        listen [::]:80;

        # Server name(s) for this virtual host
        server_name ${projectDomain};

        # Define the domain to redirect
        if ($host = ${redirect}) {
            return 301 https://$host$request_uri;
        }


        # Redirect HTTP requests to HTTPS
        return 301 https://$host$request_uri;
    }

    server {
        # Listen on port 443 for HTTPS requests
        listen 443 ssl http2;
        listen [::]:443 ssl http2;

        # Server name(s) for this virtual host
        server_name ${projectDomain};

        # SSL certificate configuration
        {{ ssl_certificate }};
        {{ ssl_certificate_key }};

        # Root directory for serving files
        {{ root }};

        # Logging configuration
        {{nginx_access_log}}
        {{nginx_error_log}}

        # Additional settings
        {{ settings }}

        # Default index file
        index index.html;

        # Static files caching and CORS headers
        location ~* ^.+\.(css|js|jpg|jpeg|gif|png|ico|gz|svg|svgz|ttf|otf|woff|woff2|eot|mp4|ogg|ogv|webm|webp|zip|swf)$ {
            add_header Access-Control-Allow-Origin "*";
            expires max;
            access_log off;
        }

        # Serve static files
        location / {
            try_files $uri $uri/ =404;
        }
    }`;

        copyToClipboard(vhost);
    };

    return (
        <div className='server-wrapper'>
            {responseBody && ( <ResponsePopup isOpen={true} onClose={() => setResponse("")} responseData={responseBody as unknown as ResponseData} />)}
            <ToastContainer />
            <div className='box-wrapper'>
                <h5>Server Hosting Guide</h5>
                <p>
                    Hostinger Server
                    <br></br>
                    <a href='https://193.203.191.131:8443' target='_blank'>
                        193.203.191.131
                    </a>
                    <br></br>
                    Admin
                </p>
            </div>
            <div className='box-wrapper'>
                <h6>Step 1</h6>                
                    <p>
                        Populate the domains DNS for www. root and dev. on{" "}
                        <a href='https://ans.glass/' target='_blank'>
                            <b>ANS</b>
                        </a>{" "}
                        with the new ip{" "}
                        <a href='https://193.203.191.131:8443' target='_blank'>
                            193.203.191.131
                        </a>
                        <br></br>If we don't own the domain, then populate the DNS on sirendigital domain with the Project Name as a prefix and <a href='https://193.203.191.131:8443' target='_blank'>
                            193.203.191.131
                        </a>.
                    </p>                
            </div>
            <div className='box-wrapper'>
                <h6>Step 2</h6>
                <p>
                    Creating the web host site on the server, fill in the details below.
                </p>
                <label>
                    <h6>Project Type:</h6>
                    <select
                        className='select-box'
                        value={projectType}
                        onChange={handleChange4}
                    >
                        <option value={1}>Static with node</option>
                        <option value={3}>Php / No node</option>
                        <option value={4}>WordPress</option>
                    </select>
                </label>

                {projectType == 1 ? (
                    <>
                        <label>
                            <div></div>
                            <div className='right-inputs'>
                                <h6>
                                    Node Version: <br></br>{" "}
                                    <span className='sm-txt'>
                                        leave blank if can run on latest
                                    </span>
                                </h6>

                                <input
                                    className='select-box'
                                    type='text'
                                    value={nodeVersion}
                                    onChange={handleChangeNodeVersion}
                                />
                            </div>
                        </label>
                        <label>
                            <div></div>
                            <div className='right-inputs'>
                                <h6>
                                    Suppress Warnings: <br></br>{" "}
                                    <span className='sm-txt'>
                                        suppress npm warnings
                                    </span>
                                </h6>
                                <input
                                    type='checkbox'
                                    checked={suppressWarnings}
                                    onChange={handleSuppressWarnings}
                                />
                            </div>
                        </label>
                    </>
                ) : null}

                <label>
                    <h6>Project Domain:</h6>
                    <input
                        className='select-box'
                        type='text'
                        value={projectDomain}
                        onChange={handleChange}
                    />
                </label>

                <label>
                    <h6>
                        Project Name (Must be the same as Site User on the
                        server):
                    </h6>
                    <input
                        className='select-box'
                        type='text'
                        value={projectName}
                        onChange={handleChange2}
                    />
                </label>
                <label>
                    <h6>
                        Dist Folder: <br></br>{" "}
                        <span className='sm-txt'>dist for astro</span>
                        <br></br>
                        <span className='sm-txt'>build for react</span>
                        <br></br>
                        <span className='sm-txt'>. if root</span>
                    </h6>
                    <input
                        className='select-box'
                        type='text'
                        value={distFolder}
                        onChange={handleChange3}
                    />
                </label>
                {projectType != 4 &&
                <label>
                    <h6>
                        Create Dev Site: <br></br>{" "}
                        <span className="sm-txt">create a dev site and <br></br> the main with .dev prefix</span>
                    </h6>
                    <input onChange={() => setDevSite(!devSite)} type='checkbox' checked={devSite} />
                </label>}
                <label>
                    <h6>
                        Use Siren Digital Domain for dev site: <br></br>{" "}
                        <span className="sm-txt">If we don't own the domain</span>
                    </h6>
                    <input onChange={() => setSirenDigital(!sirenDigital)} type='checkbox' checked={sirenDigital} />
                </label>
                <label>
                    <div className='dwn-btn'>
                        {projectType != 4 &&
                        <button onClick={executeCommand}><p>Create Site</p></button>
                        
                        }
                    </div>
                </label>
            </div>
            <div className='box-wrapper'>
                <h6>Step 3</h6>
                <p>
                    Create a folder called <b>.github </b> and then a subfolder <b>workflows</b> in the root
                    of your project, and then populate that folder with the{" "}
                    <b>.yaml</b> file.
                   
                </p> <div className='dwn-btn'>
                        <button onClick={handleDownload}>
                            <p>Download YAML File</p>
                        </button>
                    </div>
            </div>
            <div className='box-wrapper'>
                <h6>Step 4</h6>
                <p>
                    Create a repository secret for the server password called:{" "}
                    {""}
                    <b
                        style={{ cursor: "pointer" }}
                        onClick={() => copyToClipboard("SSH_PASSWORD")}
                    >
                        SSH_PASSWORD{" "}
                    </b>
                    and populate with the server password you created earlier.
                    <br></br>
                    <a href='https://docs.github.com/en/actions/security-guides/using-secrets-in-github-actions#creating-secrets-for-a-repository'>
                        + How to create a github secret
                    </a>
                </p>
            </div>
            <div className='box-wrapper'>
                <h6>Step 5</h6>
                <p>
                    See{" "}
                    <Link to='/server-setup/autoSSL'>
                        <b>AutoSSL</b>{" "}
                    </Link>
                    Page or Ask Blaine to add the SSL.
                </p>
            </div>
        </div>
    );
};

export default ServerSetup;
