import React, { useState, useEffect } from 'react';

interface TypewriterProps {
    text: string;
    delay: number;
}

const Typewriter: React.FC<TypewriterProps> = ({ text, delay }) => {
    const [currentText, setCurrentText] = useState<string>('');
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    useEffect(() => {
      if (text.length === 0) return;
        if (currentIndex < text.length) {
          const timeout = setTimeout(() => {
            setCurrentText(prevText => prevText + text[currentIndex]);
            setCurrentIndex(prevIndex => prevIndex + 1);
          }, delay);
      
          return () => clearTimeout(timeout);
        }
      }, [currentIndex, delay, text]);

    return <span>{currentText}</span>;
};

export default Typewriter;