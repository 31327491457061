import './footer.scss';

const Footer: React.FC = () => {

  return (
    <footer>
      {/* &copy; {today.getFullYear()} Siren Design. All rights reserved. */}
    </footer>
  );
};

export default Footer;
