import "./response.scss";

export interface ResponseData {
    domain: string;
    user: string;
    password: string;
}
interface ResponsePopupProps {
    isOpen: boolean;
    onClose: () => void;
    responseData: ResponseData;
}

const ResponsePopup: React.FC<ResponsePopupProps> = ({
    isOpen,
    onClose,
    responseData,
}) => {
    return (
        <div className={`popup ${isOpen ? "open" : ""}`}>
            <div className='popup-content'>
                <span className='close' onClick={onClose}>
                    &times;
                </span>
                <h2>Webserver Information</h2>
                <div className='line'>
                    <p>Domain :</p>
                    <p>{responseData.domain}</p>
                </div>
                <div className='line'>
                    <p>Profile :</p>
                    <p>{responseData.user}</p>
                </div>
                <div className='line'>
                    <p>Password :</p>
                    <p>{responseData.password}</p>
                </div>
                <p>Copy these to the google sheets before closing</p>
            </div>
        </div>
    );
};

export default ResponsePopup;
