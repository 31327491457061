import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LeftBubble from "../../components/messageBubbles/leftBubble";
import RightBubble from "../../components/messageBubbles/rightBubble";
import GptService from "../../data/gpt/gptService";
import React, { useState } from "react";
import { TextField } from "@mui/material";
import "./interactor.scss";

const Interactor: React.FC = () => {
    const [leftBubble, setLeftBubble] = useState<string[]>([]);
    const [rightBubble, setRightBubble] = useState<string[]>([]);
    const [gptMessage, setGptMessage] = useState<string>("");

    const handleGptPass = async () => {
        setLeftBubble([...leftBubble, gptMessage]);
        const tester = await GptService.gptPost(gptMessage);
        setGptMessage("");
        setRightBubble([...rightBubble, tester]);
        console.log("GPT message: " + gptMessage);
    };
    return (
        <>
            <div className='gpt-wrapper'>
                {leftBubble?.map((message, index) => (
                    <div>
                        <LeftBubble message={message} />
                        <RightBubble message={rightBubble[index]} />
                    </div>
                ))}
            </div>
            <div className="gpt-inp-wrapper">
                <TextField
                    multiline
                    className='gpt-input'
                    value={gptMessage}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && e.shiftKey) return;
                        else if (e.key === "Enter") {
                            handleGptPass();
                        }}
                }
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <AccountCircle />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position='end'>
                                <a
                                    className='enterBtn'
                                    onKeyDown={(e) =>
                                        e.key === "Enter"
                                            ? handleGptPass()
                                            : null
                                    }
                                    onClick={(e) => handleGptPass()}
                                >
                                    Enter
                                </a>
                            </InputAdornment>
                        ),
                        onChange: (e) => {
                            setGptMessage(e.target.value);
                        },
                    }}
                />
            </div>
        </>
    );
};

export default Interactor;
