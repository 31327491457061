import React, { useState } from "react";
import type { ToolkitInput } from "../../types/gpt.d";
import { Link } from "react-router-dom";
import "./toolkit.scss";
import Typography from "@mui/material/Typography";

export let input: ToolkitInput = {
    values: [
        {
            question: "What are you branding?",
            questionPrefix: "it's a ...",
        },
        {
            question: "What does it feature?",
            questionPrefix: "it has a ...",
        },
        {
            question: "Who are your audiences?",
            questionPrefix: "They are ...",
        },
        { question: "What additional keywords could represent this brand?" },
        { question: "What is the address of this scheme?" },
    ],
};

const Toolkit: React.FC = () => {
    const [count, setCount] = useState<number>(0);
    const [inputValue, setInputValue] = useState("");
    const [answers, setAnswers] = useState<string[][]>([]);
    const incrementCount = () => setCount(count + 1);
    const decrementCount = () => setCount(count - 1);

    const handleAdd = () => {
        if (!inputValue) {
            alert("Please enter a value");
            return;
        }
        const newAnswers = [...answers];
        newAnswers[count] = [...(newAnswers[count] || []), inputValue];
        input.values[count].answer = newAnswers[count].toString();
        console.log(input.values[count].answer);
        setAnswers(newAnswers);
        setInputValue("");
    };

    const renderAnswers = () =>
        answers[count]?.map((answer, index) => (
            <div key={index} className='ans-sample'>
                <p>{answer}</p>
            </div>
        ));

    const handleTextChange = (e: React.FormEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement;
        input.values[count].question = target.innerText;
    };

    return (
        <div className='hero'>
            <div className='home-wrapper hero-container'>
                <div className='wrapper'>
                    <div className='flex-row space-between'>
                        <p>
                            Stage {count + 1} / {input.values.length}
                        </p>
                    </div>
                    <div contentEditable={true} onInput={handleTextChange}>
                        <Typography>{input.values[count].question}</Typography>
                    </div>
                    <p>{input.values[count].questionPrefix}</p>
                    {renderAnswers()}

                    <div className='inputs'>
                        <input
                            type='text'
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") handleAdd();
                            }}
                        />
                        <button className='ans-btn' onClick={handleAdd}>
                            + Add More
                        </button>
                    </div>

                    <div className='button-container'>
                        {count !== 0 ? (
                            <button onClick={decrementCount}>Back</button>
                        ) : (
                            <div />
                        )}
                        {count === input.values.length - 1 ? (
                            <Link to='./result' state={{ input: input }}>
                                Generate
                            </Link>
                        ) : (
                            <button onClick={incrementCount}>Next</button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Toolkit;
