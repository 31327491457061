import { type ToolkitOutput, ToolkitInput } from "../../types/gpt.d";
import { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import GptService from "../../data/gpt/gptService";
import DisplayResponse from "./displayResponse";
import "./toolkit.scss";

const Response: React.FC = () => {
    const location = useLocation();

    const input: ToolkitInput = location.state?.input;
    const [response, setResponse] = useState<ToolkitOutput>();

    const handleApiCall = useCallback(async () => {
        try {
            const res = await GptService.callGpt(input);

            const imageResponse = await GptService.generateImage(
                res?.values.at(-1)?.answer ?? ""
            );

            res.exampleLogos = [];
            res.exampleLogos.push(imageResponse?.data[0].url ?? "");

            setResponse(res);
        } catch (error) {
            console.log("Error:", error);
        }
    }, [input]);

    useEffect(() => {
        handleApiCall();
    }, [handleApiCall]);

    return (
        <div className='hero'>
            <div className='home-wrapper'>
                <div
                    className='container'
                    style={{ width: "1400px", maxWidth: "100%" }}
                >
                    {response ? (
                        <DisplayResponse
                            toolkitOutput={response}
                            toolkitInput={input}
                        />
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                justifyContent: "center",
                            }}
                        >
                            <p>Generating</p>
                            <img style={{ width: "50px" }} src='../gear.svg' alt="cog" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Response;
