import React from "react";
import Post from "../../types/post.d";
import ImageLoader from "../imageloader/imageloader";
import { Masonry } from "@mui/lab";
import { Link } from "react-router-dom";
import './MasonryLayout.scss';

interface Props {
    posts: Post[];
}

const MasonryLayout: React.FC<Props> = ({ posts }) => {
    return (
        <div className="hero" style={{justifyContent: 'flex-start'}}>
        <Masonry columns={{ xs: 1, sm: 3 }} spacing={0}>
            {posts.map((post, index) => (
               <Link to={`/feed/${post.id}`} key={post.id} className="postThumbnail" state={{postData: post}}>
                    <ImageLoader alt={post.title} key={index} src={post.uri} />
               </Link>
            ))}
        </Masonry>
        </div>
    );
};
export default MasonryLayout;